<template>
  <v-container
    fill-height
    fluid
    class="dashboard"
  >
    <Subheader>
      <template #left>
        <h3>Practice Session</h3>
      </template>

      <template #right>
        <v-btn
          @click.stop="handleClickViewScenario"
        >
          View Scenario
        </v-btn>
        <!-- <v-btn
          color="primary white--text"
          @click="handleClickSaveSession"
        >
          End Session
        </v-btn> -->
      </template>
    </Subheader>
    <v-row>
      <v-col
        cols="7"
        class="pa-3 d-flex flex-column chat-column"
      >
        <v-card>
          <template v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </template>
          <template v-else>
            <v-card-title class="top-bar">
              <div class="employee-avatar">
                <v-badge
                  :color="isEmployeeOnline ? 'green' : 'red'"
                  dot
                  overlap
                  bottom
                  offset-x="10"
                  offset-y="10"
                >
                  <v-avatar>
                    <img
                      :src="employee.picture.thumbnail"
                      :alt="`${employee.name.first} ${employee.name.last}`"
                    >
                  </v-avatar>
                </v-badge>
              </div>

              <div class="employee-wrapper">
                <h3 class="name">
                  {{ employee.name.first }} {{ employee.name.last }}
                </h3>
                <p class="subtitle">
                  {{ isEmployeeOnline ? 'online' : 'offline' }}
                </p>
              </div>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template v-if="isSessionStart">
                <div class="message-container__wrapper">
                  <div
                    class="message-container"
                  >
                    <div
                      v-if="isSessionStart && showInitialManagerResponse"
                      class="session-start-message"
                    >
                      <div>Choose a message from the right to start chatting with {{ employee.name.first }}.</div>
                    </div>
                    <transition-group name="messages">
                      <Manager
                        v-if="showInitialManagerMessage"
                        key="initialMgrMsg"
                        :message-text="initialManagerMessage"
                        class="message-bubble"
                      />
                      <Employee
                        v-if="showInitialEmployeeMessage"
                        key="initialEmpMsg"
                        :message-text="initialEmployeeMessage"
                        class="message-bubble"
                      />
                    </transition-group>
                    <Loading :visible="isLoading" />
                  </div>
                </div>
              </template>

              <template v-if="!isSessionStart">
                <div class="message-container__wrapper">
                  <div
                    ref="message-container"
                    class="message-container overflow-y-auto"
                    max-height="550"
                  >
                    <transition-group name="messages">
                      <component
                        :is="message.type"
                        v-for="message in transcript.transcript"
                        :key="message.responseId"
                        :message-text="message.text"
                        class="message-bubble"
                      />
                    </transition-group>
                    <Loading
                      ref="loading"
                      :visible="isLoading"
                    />
                    <div
                      ref="hiddenElement"
                      class="hidden"
                    />
                  </div>
                </div>
              </template>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col
        cols="5"
        class="pa-3 d-flex flex-column response-column"
      >
        <v-card class="elevation-5 flex d-flex flex-column">
          <template v-if="transcript.inProgress">
            <v-card-title>
              Choose a response
            </v-card-title>
            <v-card-text>
              <template v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </template>
              <template v-else>
                <div
                  v-if="showInitialManagerResponse"
                  class="response-container"
                >
                  <v-chip
                    outlined
                    color="primary"
                    class="response-choice"
                    @click="clickInitialManagerResponse"
                  >
                    {{ initialManagerMessage }}
                  </v-chip>
                </div>

                <div
                  v-if="showResponses"
                  class="response-container"
                >
                  <v-chip-group
                    column
                    active-class="false"
                  >
                    <v-chip
                      v-for="response in responseChoices"
                      :key="response.responseId"
                      outlined
                      color="primary"
                      :disabled="disableButtons"
                      :response="response"
                      @click="handleChooseResponse(response)"
                    >
                      {{ response.text }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </template>
            </v-card-text>
          </template>

          <template v-else>
            <v-card-title>
              Session Complete!
            </v-card-title>
            <v-card-text>
              <h3>Score: {{ transcript.finalScore }}</h3>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <ScenarioDialog
      :visible="showScenarioDialog"
      :employee="employee"
      :scenario="scenario"
      :buttonText="isSessionStart ? 'Begin' : 'Continue'"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import { sync, get } from 'vuex-pathify';
import { sleep } from '../../util';

import Subheader from '../../components/Nav/Subheader.vue';
import Loading from '../../components/Shared/ChatLoading.vue';
import Employee from '../../components/Chat/EmployeeMessage.vue';
import Manager from '../../components/Chat/ManagerMessage.vue';
import ResponseChoice from '../../components/Chat/ResponseChoice.vue';

import ScenarioDialog from './ScenarioDialog.vue';

export default {
  name: 'ChatSession',
  components: {
    Loading,
    Employee,
    Manager,
    ResponseChoice,
    Subheader,
    ScenarioDialog,
  },
  data() {
    return {
      managerComponent: Manager,
      employeeComponent: Employee,
      responseChoices: [],
      conversationId: '',
      showResponses: false,
      initialManagerMessage: null,
      initialEmployeeMessage: null,
      isLoading: false,
      disableButtons: false,
      transcriptId: null,
      employee: {
        picture: {},
        name: {},
      },
      scenario: '',
      showScenarioDialog: false,
      isSessionStart: true,
      showInitialManagerMessage: false,
      showInitialEmployeeMessage: false,
      showInitialManagerResponse: true,
      isEmployeeOnline: false,
      loading: false,
    };
  },
  computed: {
    currentUser: get('currentUser'),
    accessToken: get('accessToken'),
    transcript: sync('transcript'),
    history(){
      return this.practiceHistory || [];
    },
  },
  created() {
    this.$bus.$on('clickResponse', this.handleChooseResponse);
    this.$bus.$on('scenarioDialogButton', this.handleClickViewScenario);
  },
  async mounted() {
    this.showScenarioDialog = true;

    this.getInitialChatData();
  },
  methods: {
    async handleChooseResponse(response) {
      if (!this.isEmployeeOnline) {
        this.isEmployeeOnline = true;
      }
      this.disableButtons = true;
      await this.saveTranscript(response);

      this.disableButtons = false;
      this.isLoading = false;
    },
    async getInitialChatData() {
      this.loading = true;
      try {
        const routeTranscriptId = this.$route.params.id;
        const config = {
          baseURL: 'http://localhost:3000/transcript',
          timeout: 120000,
           headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        const ananzi = axios.create(config);
        const res = await ananzi.post(config.baseURL, {
          userId: this.currentUser.userId,
          transcriptId: routeTranscriptId,
        }, config.headers);

        this.loading = false;

        this.conversationId = res.data.conversationId;
        this.transcript = {...res.data.transcript};

        // if the session was already in progress, set up the page a bit differently than a new one
        if (!res.data.isNewSession) {
          this.isSessionStart = false;
          this.showResponses = true;
          this.showInitialEmployeeMessage = false;
          this.showInitialManagerMessage = false;
          this.showInitialManagerResponse = false;

          this.responseChoices = res.data.responseChoices;
          this.scenario = res.data.scenario;
          this.transcriptId = res.data.transcript.transcriptId;
          this.employee = {...res.data.employee};
          // if the transcript is more than the first 2 messages, scroll to the bottom
          if (this.transcript.transcript.length > 2) {
            this.$vuetify.goTo(this.$refs.hiddenElement, {container: this.$refs['message-container'], offset: 0, duration: 300});
          }
        } else {
          this.responseChoices = res.data.responseChoices;
          this.scenario = res.data.scenario;
          this.transcriptId = res.data.transcript.transcriptId;
          this.employee = {...res.data.employee};
          this.initialManagerMessage = res.data.initialManagerMessage.text;
          this.initialEmployeeMessage = res.data.initialEmployeeMessage.text;
        }
      } catch (err) {
        console.log({err});
      }
    },
    async saveTranscript(response, isInit = false) {
      try {
        const config = {
          baseURL: 'http://localhost:3000/transcript',
          timeout: 120000,
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        const ananzi = axios.create(config);
        const res = await ananzi.post(`${config.baseURL}/${this.transcriptId}`, { conversationId: this.conversationId, response, userId: this.currentUser.userId }, config.headers);
        // remove employee response from array
        const employeeResponse = res.data.transcript.transcript.pop();

        this.transcript = {
          ...res.data.transcript,
        };
        this.$vuetify.goTo(this.$refs.hiddenElement, {container: this.$refs['message-container'], offset: 0, duration: 300});
        if (!isInit) {
          await this.handleWaitForTyping(employeeResponse, response.score);
          this.transcript.transcript.push(employeeResponse);
          this.$set(this.transcript, 'transcript', this.transcript.transcript);
          this.$vuetify.goTo(this.$refs.hiddenElement, {container: this.$refs['message-container'], offset: 0});
          this.showResponses = true;
        }
         this.responseChoices = [].concat(res.data.responseChoices);

        return {};
      } catch (err) {
        console.log({err});
      }
    },
    calculateTypingTime(message) {
      const messageLength = message.length;
      const avgTypingTime = 33.33; // 33 characters per second avg
      const typingTimeSeconds = messageLength / avgTypingTime;
      return typingTimeSeconds * 1000;
    },
    async handleWaitForTyping(response, score) {
      console.log('RESPONSE SCORE', score);
      const typingTime = this.calculateTypingTime(response.text);
      console.log('TYPING TIME', typingTime);
      switch (score) {
        case 1:
          await sleep(2500);
          this.isLoading = true;
          await sleep(1500);
          this.isLoading = false;
          await sleep(1500);
          this.isLoading = true;
          await sleep(typingTime);
          this.isLoading = false;
          return;
        case 2:
          await sleep(2000);
          this.isLoading = true;
          await sleep(1000);
          this.isLoading = false;
          await sleep(2500);
          this.isLoading = true;
          await sleep(typingTime);
          this.isLoading = false;
          return;
        case 3:
          await sleep(2000);
          this.isLoading = true;
          await sleep(typingTime);
          return;
        case 4:
          await sleep(2000);
          this.isLoading = true;
          await sleep(typingTime);
          return;
      }
          await sleep(2000);
          this.isLoading = true;
          // TODO: calculate typing time based on mgr response
          await sleep(2000);
    },
    handleClickViewScenario() {
      this.showScenarioDialog = !this.showScenarioDialog;
    },
    async clickInitialManagerResponse() {
      this.showInitialManagerMessage = true;
      this.showInitialManagerResponse = false;
      await sleep(1000);
      this.isLoading = true;
      this.isEmployeeOnline = true;
      await sleep(2000);
      this.showInitialEmployeeMessage = true;
      this.isLoading = false;
      await sleep(1000);
      this.isSessionStart = false;
      this.showResponses = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 12px 24px;
}
.row {
  height: 100%;
}
.chat-column {
  height: 100% !important;
}
.response-column {
  height: 50% !important;
}

.message-container {
  padding: 24px 40px 24px 24px;
  height: 550px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
}
.employee-avatar {
  width: 80px;
  align-self: flex-start;
}

.employee-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.subtitle {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 0;
  line-height: 12px;
  text-align: center;
}

.session-start-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background: $trana-grey-light;
  opacity: .5;
  border-radius: 20px;
  padding: 12px;
  color: black;
  width: 100%;
  text-align: center;
}

.loading {
  scroll-snap-align: end;
}

.response-container {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-chip {
  white-space: pre-line;
  height: fit-content !important;

  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .v-chip__content {
     padding: 8px 0;
  }
}

::v-deep .v-chip .v-chip__content {
  padding: 8px 0;
}
</style>
