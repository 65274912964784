<template>
  <v-container>
    <v-row>
      <v-card
        class="login-card"
        elevation="3"
      >
        <v-img
          contain
          max-height="150"
          width="250"
          src="../assets/hexies.svg"
        />
        <h3>
          Welcome to Trana Solutions
        </h3>
        <v-btn
          color="primary"
          width="100%"
          @click="login"
        >
          Sign In
        </v-btn>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() {
      return {
        // user: this.$auth0.user,
      };
    },
  mounted() {

  },
  methods: {
    async login() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display:flex;
  justify-content: center;
  align-content: center;
}
.login-card {
  margin-top: 200px;
  padding: 20px;
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
::v-deep .theme--light.v-application {
  background: red !important;
}
</style>
