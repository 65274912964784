<template>
  <div class="loading-wrapper">
    <div :class="loadingClasses">
      <div class="dot-typing" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    loadingClasses() {
      return {
        loading: true,
        hidden: !this.visible,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
}
.loading {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  width: 58px;
  border-radius: 50px;
  background: $trana-grey-light;
  height: 40px;
  scroll-snap-align: end;
}

.slot {
  margin-bottom: 20px;
}

.hidden {
  visibility: hidden;
}

.dot-typing {
  margin-top: 15px;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $trana-grey-dark;
  color: $trana-grey-dark;
  box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
    10014px 0 0 0 $trana-grey-dark;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
  50% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px -10px 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px -10px 0 0 $trana-grey-dark;
  }
  100% {
    box-shadow: 9984px 0 0 0 $trana-grey-dark, 9999px 0 0 0 $trana-grey-dark,
      10014px 0 0 0 $trana-grey-dark;
  }
}
</style>
