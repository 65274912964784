<template>
  <v-container
    fluid
    fill-height
  >
    <Subheader>
      <template #left>
        <h3>Practice</h3>
      </template>
      <template #right>
        <v-btn
          color="primary white--text"
          @click="handleStartChat"
        >
          <v-icon
            left
            dark
          >
            mdi-plus
          </v-icon>
          Start Session
        </v-btn>
      </template>
    </Subheader>
    <v-row>
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-text>
            <h2>Session History</h2>
            <template v-if="!completedSessions.length">
              <v-list>
                <v-list-item>
                  No completed sessions available
                </v-list-item>
              </v-list>
            </template>
            <template v-else>
              <v-list>
                <v-list-item
                  v-for="session in completedSessions.slice(0, 12)"
                  :key="session.transcriptId"
                  dense
                >
                  <router-link
                    :to="`/practice/history/${session.transcriptId}`"
                    class="history-link"
                  >
                    {{ session.conversationName }} {{ session.formattedDate }}
                  </router-link>
                </v-list-item>
              </v-list>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!completedSessions.length"
              text
              color="primary"
              @click="handleStartChat"
            >
              Start Now
            </v-btn>
            <v-btn
              v-else
              text
              color="primary"
              to="/practice/history"
            >
              See All Sessions
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-text>
            <h2>
              Sessions In Progress
            </h2>
            <template v-if="!inProgressSessions.length">
              <v-list>
                <v-list-item dense>
                  No incomplete sessions available
                </v-list-item>
              </v-list>
            </template>
            <template v-else>
              <v-list>
                <v-list-item
                  v-for="session in inProgressSessions.slice(0, 12)"
                  :key="session.transcriptId"
                  dense
                >
                  <router-link
                    :to="`/practice/session/${session.transcriptId}`"
                    class="history-link"
                  >
                    {{ session.conversationName }} {{ session.formattedDate }}
                  </router-link>
                </v-list-item>
              </v-list>
            </template>
          </v-card-text>
          <v-card-actions v-if="inProgressSessions.length">
            <v-spacer />
            <v-btn
              text
              color="primary"
              to="/practice/history"
            >
              See All Sessions
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import uuid from 'short-uuid';
import { DateTime } from 'luxon';
import { get } from 'vuex-pathify';

import Subheader from '../../components/Nav/Subheader.vue';

export default {
  name: 'PracticeLanding',
  components: {
    Subheader,
  },
  data() {
    return {
      inProgressSessions: [],
      completedSessions: [],
      loading: false,
      error: null,
      inProgressTotal: 0,
      completedTotal: 0,
    };
  },
  computed: {
    currentUser: get('currentUser'),
    accessToken: get('accessToken'),
  },
  mounted() {
    // get chat history
    this.getChatHistory();
  },
  methods: {
    handleStartChat() {
      const newTranscriptId = uuid.generate();

      this.$router.push({
        path: `/practice/session/${newTranscriptId}`,
      });
    },
    async getChatHistory() {
      const config = {
        baseURL: 'http://localhost:3000',
        timeout: 120000,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      const ananzi = axios.create(config);
      const {data} = await ananzi.get(`${config.baseURL}/transcript/history/${this.currentUser.userId}`, config.headers);
      const modifiedTranscripts = data.transcripts.map((transcript) => {
        const formattedDate = DateTime.fromISO(transcript.updatedAt).toLocaleString(DateTime.DATE_SHORT);
        return {
          ...transcript,
          formattedDate,
          conversationName: 'Absences Discussion',
        };
      });
      this.completedSessions = modifiedTranscripts.filter(x => !x.inProgress);
      this.inProgressSessions = modifiedTranscripts.filter(x => x.inProgress);
      this.completedTotal = this.completedSessions.length;
      this.inProgressTotal = this.inProgressSessions.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  height: 100%;
}
.container {
  padding: 12px 24px;
}
.v-card__text {
  height: 100% !important;

  &.bars-container {
    padding: 0;
  }
}

::v-deep .v-window__prev, ::v-deep .v-window__next {
  margin: 0;
}
::v-deep .v-window-item {
  margin: 0 30px 30px;
}
</style>
