/**
 * Awaitable timeout
 * @param {Number} ms time in milliseconds to sleep
 */
function sleep(ms) {
  console.log({ms})
  return new Promise(resolve => setTimeout(() => resolve(), ms));
}

module.exports = {
    sleep,
};
