<template>
  <v-container>
    <v-row
      full-height
      fluid
    >
      <v-col>
        <v-card>
          <v-sparkline
            height="300"
            width="500"
            :value="scores"
            auto-draw
            :gradient="gradient"
            smooth="10"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { get } from 'vuex-pathify';

const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ];

export default {
  name: 'Practice',
  components: {},
  data() {
    return {
      scores: [],
      sessions: 0,
      gradient: gradients[5],
    };
  },
  computed: {
    currentUser: get('currentUser'),
    accessToken: get('accessToken'),
  },
  mounted() {
    this.getStatistics();

  },

  methods: {
    async getStatistics() {
      const config = {
        baseURL: 'http://localhost:3000',
        timeout: 120000,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      const ananzi = axios.create(config);
      const {data} = await ananzi.get(`${config.baseURL}/statistics/${this.currentUser.userId}`, config.headers);
      this.scores = data.scores;
      this.sessions = data.sessions;
    },
  },
};
</script>

<style lang="scss" scoped></style>
