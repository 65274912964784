<template>
  <v-app-bar
    app
    class="subheader"
    elevation="0"
    color="white"
  >
    <slot name="left" />
    <v-spacer />
    <slot name="right" />
  </v-app-bar>
</template>

<script>
export default {
  name: 'Subheader',
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
  .subheader {
    margin-top: 56px !important;

    &::v-deep .v-toolbar__content {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    }
  }
</style>
