<template>
  <v-container
    fluid
    class="dashboard"
  >
    <Subheader>
      <template #left>
        <h3>Welcome, {{ currentUser.firstName }}</h3>
      </template>
    </Subheader>
    <v-row>
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-text>
            <p class="text-h5">
              Ready to practice?
            </p>
            <p>Practicing daily can help retain your skills.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="primary"
              to="/practice"
            >
              Start Now
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-text>
            <p class="text-h5">
              Completed Sessions
            </p>
            <p class="text-h4 font-weight-bold">
              10
            </p>
            <p class="text-h5">
              Average Score
            </p>
            <p class="text-h4 font-weight-bold">
              95
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="primary"
              to="/statistics"
            >
              See Your Stats
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-text>
            <BarsCarousel />
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { get } from 'vuex-pathify';
import Subheader from '../components/Nav/Subheader.vue';
// import BarsCarousel from '../components/Shared/BarsCarousel.vue';

export default {
  name: 'Dashboard',
  components: {
    Subheader,
    // BarsCarousel,
  },
  props: {
    trana: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentUser: get('currentUser'),
  },
  async created() {
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 24px;
}
.v-card__text {
  height: 100%;
}
</style>
