<template>
  <v-app-bar
    clipped-left
    app
    color="orange lighten-1"
    dark
    height="56"
    elevation="0"
  >
    <v-img
      alt="Trana Logo"
      contain
      src="../../assets/trana-logo.svg"
      transition="scale-transition"
      class="shrink"
      max-height="40"
      position="left"
       height="56"
    />
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">

</style>
