<template>
  <v-timeline
    align-top
    dense
  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <v-card
        :color="item.color"
        dark
        max-width="380"
      >
        <v-card-title class="text-h6">
          {{ item.title }}
        </v-card-title>
        <v-card-text class="white text--primary">
          <div class="py-2">{{ item.description1 }}</div>
          <div>{{ item.description2 }}</div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
  export default {
    name: 'RatingScale',
    data() {
        return {

      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-seed',
          title: 'Level One: Learning',
          description1: 'Consistently does not meet expectations or exhibit behaviors that reflect implementation of the competency.',
          description2: 'Reviewing material or additional resources to gain a better understanding of what is expected is suggested, in addition to more practice time.',
        },
        {
          color: 'orange lighten-2',
          icon: 'mdi-sprout',
          title: 'Level Two: Emerging',
          description1: 'Needs to be more consistent to be effective.',
          description2: 'Has an understanding of the material but may need a refresher or simply more practice time.',
        },
        {
          color: 'green lighten-2',
          icon: 'mdi-flower',
          title: 'Level Three: Mastering',
          description1: 'With continued practice, this competency will become habit or second nature.',
        },
      ],

        };
    },
  };
</script>
