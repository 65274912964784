<template>
  <v-dialog
    :value="visible"
    overlay-opacity=".95"
    width="500"
    @click:outside="handleClickButton"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Practice Session Scenario
      </v-card-title>
      <v-card-text>
        {{ employee.name.first }} has been with the company for 5 years. Suddenly, {{ pronoun }} is arriving late, leaving early,
        and calling in sick.
        <br>
        <br>
        As the manager, you need to identify the issue at hand and how best to move forward. Is it poor job
        satisfaction, not feeling valued, low motivation, or personal matters?
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="handleClickButton"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ScenarioDialog',
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    employee: {
      type: Object,
      required: true,
    },
    scenario: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  computed: {
    pronoun() {
      return this.employee.gender === 'male' ? 'he' : 'she';
    },
  },
  methods: {
    handleClickButton() {
      this.$bus.$emit('scenarioDialogButton');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 20px 24px;
}
</style>
