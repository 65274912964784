import Vue from 'vue';

const bus = new Vue({
    name: 'EventBus',
});

export default {
    install: function(Vue) {
        Vue.prototype.$bus = bus;
    },
};
