<template>
  <v-navigation-drawer
    app
    permanent
    width="240px"
    absolute
    class="side-drawer"
  >
    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.route"
        exact
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          {{ item.title }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideDrawer',
  components: {},
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/dashboard' },
        { title: 'Practice', icon: 'mdi-dumbbell', route: '/practice' },
        { title: 'Knowledge Base', icon: 'mdi-book-open-page-variant-outline', route: '/knowledge' },
        { title: 'Statistics', icon: 'mdi-chart-bar', route: '/statistics' },
        { title: 'Settings', icon: 'mdi-account-cog', route: '/settings' },
      ],
      mini: true,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
  .side-drawer {
    top: 56px !important;
    max-height: calc(100% - 60px) !important;
    height: calc(100vh - 60px) !important;
  }
  .v-list {
    padding-top: 24px;
  }
  .v-list-item--active .v-list-item__icon {
    opacity: 1;
    color: $trana-orange-normal !important;
  }
</style>
