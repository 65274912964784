import { render, staticRenderFns } from "./ManagerMessage.vue?vue&type=template&id=48701a42&scoped=true&"
import script from "./ManagerMessage.vue?vue&type=script&lang=js&"
export * from "./ManagerMessage.vue?vue&type=script&lang=js&"
import style0 from "./ManagerMessage.vue?vue&type=style&index=0&id=48701a42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48701a42",
  null
  
)

export default component.exports