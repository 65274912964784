<template>
  <div
    class="user-message"
    :class="transcriptClass"
  >
    <ChatBubble direction="right">
      {{ messageText }}
    </ChatBubble>
  </div>
</template>

<script>
import ChatBubble from './ChatBubble.vue';
export default {
  name: 'Manager',
  components: { ChatBubble },
  props: {
    messageText: {
      type: String,
      default: '',
    },
    transcriptClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.user-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;

   &:last-child {
    scroll-snap-align: end;
  }
}
</style>
