<template>
  <div class="bot-message">
    <!-- <v-avatar
      color="primary"
      size="60"
    /> -->
    <ChatBubble direction="left">
      {{ messageText }}
    </ChatBubble>
  </div>
</template>

<script>
import ChatBubble from '../../components/Chat/ChatBubble.vue';
export default {
  name: 'Employee',
  components: { ChatBubble },
  props: {
    messageText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.bot-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;

  &:last-child {
    scroll-snap-align: end;
  }
}

.avatar {
  margin-right: 40px;
}

</style>
