<template>
  <div :class="bubbleClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ChatBubble',
  props: {
    direction: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    bubbleClasses() {
      return {
        bubble: true,
        [this.direction]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  width: 400px;
  border-radius: 15px;
  padding: 20px;
  font-weight: 500;
  position: relative;
  display: block;
  box-shadow: 1px 1px 2px 0px rgba(43, 56, 54, 0.5);

  &.right {
    background: $trana-blue-dark;
    text-align: left;
    color: $trana-white;
  }

  &.left {
    background: $trana-grey-light;
    color: $trana-black;

    text-align: left;
  }
}

.right:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid $trana-blue-dark;
  border-right: 15px solid transparent;
  border-top: 15px solid $trana-blue-dark;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}

.left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid $trana-grey-light;
  border-top: 15px solid $trana-grey-light;
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}

</style>
