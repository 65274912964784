<template>
  <v-container
    fill-height
    fluid
    class="dashboard"
  >
    <Subheader>
      <template #left>
        <h3>Session Transcript</h3>
      </template>

      <template #right>
        <v-btn
          @click.stop="handleClickViewScenario"
        >
          View Scenario
        </v-btn>
      </template>
    </Subheader>
    <v-row>
      <v-col
        cols="7"
        class="pa-3 d-flex flex-column chat-column"
      >
        <v-card>
          <v-card-title class="top-bar">
            <div class="employee-avatar">
              <v-badge
                color="red"
                dot
                overlap
                bottom
                offset-x="10"
                offset-y="10"
              >
                <v-avatar>
                  <img
                    :src="employee.picture.thumbnail"
                    :alt="`${employee.name.first} ${employee.name.last}`"
                  >
                </v-avatar>
              </v-badge>
            </div>

            <div class="employee-wrapper">
              <h3 class="name">
                {{ employee.name.first }} {{ employee.name.last }}
              </h3>
              <p class="subtitle">
                offline
              </p>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div class="message-container__wrapper">
              <div
                ref="message-container"
                class="message-container overflow-y-auto"
                max-height="550"
              >
                <transition-group name="messages">
                  <component
                    :is="message.type"
                    v-for="message in transcript.transcript"
                    :key="message.responseId"
                    :message="message"
                    :messageText="message.text"
                    :transcript-class="message.type === 'Manager' ? `transcript-message__manager-${message.score}` : ''"
                    class="message-bubble"
                  />
                </transition-group>
                <Loading
                  ref="loading"
                  :visible="isLoading"
                />
                <div
                  ref="hiddenElement"
                  class="hidden"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="5"
        class="pa-3 d-flex flex-column response-column"
      >
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-title>
            Overview
          </v-card-title>
          <v-card-text>
            <p><span class="font-weight-bold">Final Score:</span> {{ finalScore }}</p>
            <p><span class="font-weight-bold">Possible Score:</span> {{ possibleScore }}</p>
            <p><span class="font-weight-bold">Average Response Score:</span> {{ avgResponseScore }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ScenarioDialog
      :visible="showScenarioDialog"
      :employee="employee"
      :scenario="scenario"
      buttonText="Review"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import { sync, get } from 'vuex-pathify';

import Subheader from '../../components/Nav/Subheader.vue';
import Loading from '../../components/Shared/ChatLoading.vue';
import Employee from '../../components/Chat/EmployeeMessage.vue';
import Manager from '../../components/Chat/TranscriptMgrMessage.vue';
import ResponseChoice from '../../components/Chat/ResponseChoice.vue';

import ScenarioDialog from './ScenarioDialog.vue';

export default {
  name: 'Transcript',
  components: {
    Loading,
    Employee,
    Manager,
    ResponseChoice,
    Subheader,
    ScenarioDialog,
  },
  data() {
    return {
      managerComponent: Manager,
      employeeComponent: Employee,
      responseChoices: [],
      conversationId: '',
      showResponses: false,
      initialManagerMessage: null,
      initialEmployeeMessage: null,
      isLoading: false,
      disableButtons: false,
      transcriptId: null,
        employee: {
        picture: {},
        name: {},
      },
      showScenarioDialog: false,
      isSessionStart: true,
      showInitialManagerMessage: false,
      showInitialEmployeeMessage: false,
      showInitialManagerResponse: true,
      isEmployeeOnline: false,
      finalScore: 0,
      possibleScore: 0,
      avgResponseScore: 0,
      scenario: '',
    };
  },
  computed: {
    currentUser: get('currentUser'),
    accessToken: get('accessToken'),
    transcript: sync('transcript'),
  },
  created() {
    this.$bus.$on('clickResponse', this.handleChooseResponse);
    this.$bus.$on('scenarioDialogButton', this.handleClickViewScenario);
  },
  async mounted() {
    this.showScenarioDialog = true;

    this.getTranscript();
  },
  methods: {
    handleClickViewScenario() {
      this.showScenarioDialog = !this.showScenarioDialog;
    },
    async getTranscript() {
      const routeTranscriptId = this.$route.params.id;
      try {
        const config = {
          baseURL: 'http://localhost:3000/transcript',
          timeout: 120000,
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        };

        const ananzi = axios.create(config);
        const { data } = await ananzi.get(`${config.baseURL}/${routeTranscriptId}/${this.currentUser.userId}`, config.headers);
        this.transcript = {
          ...data.transcript,
        };
        this.finalScore = data.score.finalScore;
        this.possibleScore = data.score.possibleScore;
        this.avgResponseScore = data.score.averageResponseScore;
        this.employee = data.transcript.employee;
        this.scenario = data.conversation.scenario;

      } catch (err) {
        console.log({err});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 12px 24px;
}
.row {
  height: 100%;
}
.chat-column {
  height: 100% !important;
}
.response-column {
  height: 50% !important;
}

.message-container {
  padding: 24px 40px 24px 24px;
  height: 550px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
}
.employee-avatar {
  width: 80px;
  align-self: flex-start;
}

.employee-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.subtitle {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 0;
  line-height: 12px;
  text-align: center;
}

.session-start-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background: $trana-grey-light;
  opacity: .5;
  border-radius: 20px;
  padding: 12px;
  color: black;
  width: 100%;
  text-align: center;
}

.loading {
  scroll-snap-align: end;
}

.response-container {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-chip {
  white-space: pre-line;
  height: fit-content !important;

  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .v-chip__content {
     padding: 8px 0;
  }
}

::v-deep .v-chip .v-chip__content {
  padding: 8px 0;
}
</style>
