import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { make } from 'vuex-pathify';

import pathify from './pathify';

Vue.use(Vuex);

const state = {
  isUserAuthed: false,
  userRequested: false,
  userNotFound: false,
  currentUser: null,
  auth0User: null,
  transcript: [],
  accessToken: null,
};

const mutations = {
  ...make.mutations(state),
};

const actions = {
  async validateUser({ state }, {email, accessToken}) {
    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };
      const response = await axios.get(`http://localhost:3000/user/${email}/auth`, config);

      if (response.status === 200) {
        state.userNotFound = false;
        state.currentUser = response.data;
      } else {
        state.userNotFound = true;
      }
    } catch (err) {
      state.userNotFound = false;
    }
  },
  async getAccessToken() {
    const token = await this.$auth0.getAccessTokenSilently();
    return token;
  },
};

export default new Vuex.Store({
  plugins: [
    pathify.plugin,
  ],
  state,
  mutations,
  getters: {
  },

  actions,
  modules: {
  },
});
