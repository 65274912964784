import Vue from 'vue';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import Bus from './plugins/eventBus';
import store from './store';
import router from './router';
import { Auth0Plugin } from './plugins/auth0';

const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const serverUrl = 'http://localhost:3000';
Vue.use(Bus);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId:  process.env.VUE_APP_AUTH0_CLIENTID,
  audience,
  serverUrl,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.config.productionTip = false;

window.vm = new Vue({
  name: 'Trana',
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
