<template>
  <button
    class="response-choice"
    :disabled="disabled"
    @click="handleClickResponse(response)"
  >
    <div class="response-text">
      <slot />
    </div>
    <!-- <font-awesome-icon
      icon="paper-plane"
      class="send-icon"
    /> -->
  </button>
</template>

<script>
export default {
  name: 'ResponseChoice',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    response: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'clickResponse',
  ],
  data() {
    return {};
  },
  mounted() {},

  methods: {
    handleClickResponse() {
      console.log('here');
      this.$bus.$emit('clickResponse', this.response);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-choice {
  background-color: $trana-grey-light;
  border-radius: 4px;
  height: 110px;
  width: 150px;
  display: flex;
  position: relative;
  padding: 20px;
  justify-content: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:last-child {
    margin-right: -20px;
  }

  &:hover {
    transition: 150ms;
    box-shadow: 1px 1px 10px 0px rgba(43, 56, 54, 0.5);

    & .send-icon {
      color: $trana-success-dark;
      animation-duration: 2s;
      animation-iteration-count: 3;
      animation-name: bounce;
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
  }
}

.response-choice:disabled {
  &:hover {
    & .send-icon {
      color: $trana-success-dark-T60;
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      animation: none !important;
    }
  }
}

.send-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: $trana-success;
  font-size: 30px;
  align-self: flex-end;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-0.7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
