<template>
  <v-container
    fluid
    fill-height
  >
    <Subheader>
      <template #left>
        <h3>Session History</h3>
      </template>
    </Subheader>
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="sessions"
            :items-per-page="20"
            class="elevation-1"
            height="auto"
            fixed-header
            @click:row="handleClickTableRow"
          >
            <template #item.inProgress="{item}">
              <v-chip
                v-if="item.inProgress"
                class="ma-2"
                label
                color="amber lighten-4"
              >
                In Progress
              </v-chip>
              <v-chip
                v-else
                class="ma-2"
                label
                color="green lighten-4"
              >
                Completed
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import { sync, get } from 'vuex-pathify';

import Subheader from '../Nav/Subheader.vue';

export default {
    name: 'PracticeHistoy',
    components: {
        Subheader,
    },
    data() {
        return {
            headers:[
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'formattedDate',
                },
                {
                    text: 'Conversation Name',
                    align: 'start',
                    sortable: false,
                    value: 'conversationName',
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'inProgress',
                },
                {
                    text: 'Final Score',
                    align: 'start',
                    sortable: false,
                    value: 'finalScore',
                },
                {
                    text: 'Average Response Score',
                    align: 'start',
                    sortable: false,
                    value: 'avgResponseScore',
                },
            ],
            sessions:[],
        };
    },
    computed: {
        currentUser: get('currentUser'),
        accessToken: get('accessToken'),
    },
    mounted() {
      this.getPracticeSessions();
    },
    methods: {
        async getPracticeSessions() {
            const config = {
                baseURL: 'http://localhost:3000',
                timeout: 120000,
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                },
            };
            const ananzi = axios.create(config);
            const { data } = await ananzi.get(`${config.baseURL}/transcript/history/${this.currentUser.userId}`, config.headers);
            this.sessions = data.transcripts.map((transcript) => {
                const formattedDate = DateTime.fromISO(transcript.updatedAt).toLocaleString(DateTime.DATE_SHORT);
                console.log({transcript})
                return {
                    ...transcript,
                    formattedDate,
                    conversationName: 'Absences Discussion',
                };
            });
            console.log({sessions: this.sessions});
        },
        handleClickTableRow(item) {
            if (item.inProgress) {
                this.$router.push({
                    path: `/practice/session/${item.transcriptId}`,
                });
            } else {
                this.$router.push({
                    path: `/practice/history/${item.transcriptId}`,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 24px;
}
.row, .v-card, .v-data-table {
    height: 100%;
}
</style>
