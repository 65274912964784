<template>
  <div
    class="user-message"
    :class="transcriptClass"
  >
    <v-tooltip
      right
      max-width="300"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="bubble right"
          v-on="on"
        >
          {{ message.text }}
        </div>
      </template>
      <div>Score: {{ message.score }}</div>
      <div>Score reason: {{ message.competencyScoreReason }}</div>
      <div>BARS: {{ message.bars }}</div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Manager',
  components: { },
  props: {
    message: {
      type: Object,
      default: null,
    },
    transcriptClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  width: 400px;
  border-radius: 15px;
  padding: 20px;
  font-weight: 500;
  position: relative;
  display: block;
  box-shadow: 1px 1px 2px 0px rgba(43, 56, 54, 0.5);

  &.right {
    background: $trana-blue-dark;
    text-align: left;
    color: $trana-white;
  }
}

.right:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid $trana-blue-dark;
  border-right: 15px solid transparent;
  border-top: 15px solid $trana-blue-dark;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}

.user-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;

   &:last-child {
    scroll-snap-align: end;
  }
}

.transcript-message__manager-1 .bubble.right:hover {
  background: $trana-error;
  opacity: .95;
  cursor: pointer;

  & ::v-deep.right:before {
    border-left: 15px solid $trana-error;
    border-top: 15px solid $trana-error;
  }
}
.transcript-message__manager-2 .bubble.right:hover {
  background: $trana-warning;
  opacity: .85;
  cursor: pointer;

  & ::v-deep.right:before {
    border-left: 15px solid $trana-warning;
    border-top: 15px solid $trana-warning;
  }
}
.transcript-message__manager-3 .bubble.right:hover {
  background: $trana-success;
  opacity: .85;
  cursor: pointer;

  & ::v-deep.right:before {
    border-left: 15px solid $trana-success;
    border-top: 15px solid $trana-success;
  }
}

</style>
