<template>
  <v-container>
    <Subheader>
      <template #left>
        <h3>Settings</h3>
      </template>
    </Subheader>
    <v-row>
      <v-col class="settings">
        <v-card>
          <v-card-title>
            Update User Details
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="form-element">
                <v-text-field
                  v-model.trim="userDetails.firstName"
                  :rules="nameRules"
                  color="#0078B3"
                  label="First name"
                />
              </div>
              <div class="form-element">
                <v-text-field
                  v-model.trim="userDetails.lastName"
                  :rules="nameRules"
                  color="#0078B3"
                  label="Last name"
                />
              </div>
              <div class="form-element">
                <v-text-field
                  v-model.trim="userDetails.email"
                  :rules="emailRules"
                  color="#0078B3"
                  label="Email"
                />
              </div>
              <v-select
                v-model="userDetails.pronouns"
                :items="pronounChoices"
                label="Pronouns"
              />
            </v-form>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="mr-4 white--text"
                @click="handleSubmitChanges"
              >
                Submit Changes
              </v-btn>

              <p
                v-if="formError"
                class="error-message"
              >
                {{ formError }}
              </p>
              <p
                v-if="formSuccess"
                class="success-message"
              >
                {{ formSuccess }}
              </p>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="legal">
        <h2>Legal Disclaimers</h2>
        <p>
          This is where all the stuff about how we use data and all that stuff
          will go.
        </p>
        <p>Cookie policy?</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import axios from 'axios';

import Subheader from '../components/Nav/Subheader.vue';

export default {
  name: 'Settings',
  components: {
    Subheader,
  },
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      pronounChoices: [
        'she/her/hers',
        'he/him/his',
        'they/them/theirs',
      ],
      formError: '',
      formSuccess: '',
      userDetails: {}
    };
  },
  computed: {
    currentUser: sync('currentUser'),
    accessToken: get('accessToken'),
  },
  mounted() {
    this.userDetails = {...this.currentUser};
  },
  methods: {
    validate () {
      this.$refs.form.validate();
    },
    async handleSubmitChanges() {
      this.formError = '';
      this.formSuccess = '';

      const config = {
        baseURL: 'http://localhost:3000',
        timeout: 120000,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      };

      const ananzi = axios.create(config);
      const { status, data } = await ananzi.patch(`${config.baseURL}/user/${this.currentUser.userId}`, {user: this.userDetails}, config.headers);
      
      if (status !== 204) {
        this.formError = 'A problem has occurred submitting this form. Please try again later.';
      } else {
        this.formSuccess = 'Success! Your information has been saved.';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  height: 100%;
}
.container {
  padding: 24px;
}

.error-message {
  color: $trana-error;
  margin-bottom: 0;
}
.success-message {
  color: $trana-success;
  margin-bottom: 0;
}
</style>
