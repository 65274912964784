<template>
  <v-container
    fill-height
    fluid
    class="dashboard"
  >
    <Subheader>
      <template #left>
        <h3>Knowledge Base</h3>
      </template>
    </Subheader>
    <v-row>
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-title>
            Behaviorally Anchored Ranking System
          </v-card-title>
          <v-card-text class="carousel-container">
            <BarsCarousel />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pa-3 d-flex flex-column">
        <v-card class="elevation-5 flex d-flex flex-column">
          <v-card-title>
            Managerial Coaching Competencies
          </v-card-title>
          <v-card-text class="carousel-container">
            <RatingScale />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Subheader from '../components/Nav/Subheader.vue';
import BarsCarousel from '../components/Shared/BarsCarousel.vue';
import RatingScale from '../components/Shared/RatingScale.vue';

export default {
  name: 'KnowledgeBase',
   components: {
    Subheader,
    BarsCarousel,
    RatingScale,
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 50px;
}
.dashboard-card {
  height: 100%;
}
.row {
  height: 100%;
}
.container {
  padding: 12px 24px;
}

.v-card__text {
  height: 100% !important;

  &.carousel-container {
    padding: 0;
  }
}

::v-deep .v-window__prev, ::v-deep .v-window__next {
  // margin: 0;
}
::v-deep .v-window-item {
  margin: 0 30px 30px;
}
</style>
