/* eslint-disable vue/no-deprecated-v-bind-sync */
<template>
  <v-app>
    <!-- <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay> -->
    <Login v-if="!isUserAuthed && !userNotFound" />

    <template v-else>
      <AppBar />
      <SideDrawer />

      <!-- Navbar/app bar need to go here -->
      <v-main v-if="isUserAuthed && !userNotFound">
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { get, sync } from 'vuex-pathify';

import AppBar from './components/Nav/AppBar.vue';
import SideDrawer from './components/Nav/SideDrawer.vue';
import Login from './components/Login.vue';

export default {
  name: 'App',
  components: {
    Login,
    SideDrawer,
    AppBar,
  },
  data () {
    return {
    };
  },
  computed: {
    isUserAuthed: sync('isUserAuthed'),
    userRequested: sync('userRequested'),
    auth0User: sync('auth0User'),
    accessToken: sync('accessToken'),
    userNotFound: get('userNotFound'),
  },
  async mounted() {
    await this.authUser();
  },
  methods: {
    ...mapActions([
      'validateUser',
    ]),
    loginWithRedirect() {
      this.$auth.loginWithRedirect();
    },
    async authUser() {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        this.accessToken = accessToken;
        await this.validateUser({email: this.$auth.user.email, accessToken});
        this.isUserAuthed = this.$auth.isAuthenticated;

        if (!this.$auth.user) {
          this.loginWithRedirect();
        } else {
          this.auth0User = this.$auth.user;
        }
      } catch (err) {
        console.log('authUserErr', err);
      }

    },
  },
};
</script>

<style lang="scss">
  .v-main {
    background-color: #f8f8f8;
  }

  .container {
    padding: 12px 24px;
  }
</style>
