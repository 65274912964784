import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Settings from '../views/Settings.vue';
import KnowledgeBase from '../views/KnowledgeBase.vue';
import App from '../App.vue';

import ChatSession from '../components/Practice/ChatSession.vue';
import PracticeLanding from '../components/Practice/Landing.vue';
import Transcript from '../components/Practice/Transcript.vue';
import History from '../components/Practice/History.vue';
import Statistics from '../views/Statistics.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: App,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/practice',
    name: 'Practice',
    component: () => import('../views/Practice.vue'),
    children: [
      {
        path: '',
        component: PracticeLanding,
      },
      {
        path: 'session/:id',
        component: ChatSession,
      },
      {
        path: 'history',
        component: History,
      },
      {
        path: 'history/:id',
        component: Transcript,
      },
    ],
  },
  {
    path: '/results',
    name: 'Results',
    component: () => import('../views/Results.vue'),
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/knowledge',
    name: 'Knowledge Base',
    component: KnowledgeBase,
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
