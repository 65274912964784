<template>
  <v-carousel
    cycle
    interval="10000"
    height="100%"
  >
    <v-carousel-item
      v-for="(bar, i) in bars"
      :key="i"
    >
      <h3>
        {{ bar.name }}
      </h3>
      <p>
        {{ bar.description }}
      </p>
      <v-list>
        <v-list-item
          v-for="(quality, j) in bar.qualities"
          :key="j"
        >
          {{ quality }}
        </v-list-item>
      </v-list>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
    name: 'BarsCarousel',
    data() {
        return {
            bars: [
                {
                    name: 'Contracting',
                    description: 'Contracting is partnering with the employee in what they want to accomplish. It’s also defining success and addressing related issues.',
                    qualities: [
                        'Coach asks the employee what they want to work on during the session.',
                        'Coach continues to follow the employee’s agenda throughout the session.',
                        'Coach helps the employee identify what they want to accomplish in the session.',
                        'Coach explores what is important to the employee about what they want to accomplish.',
                    ],
                },
                {
                    name: 'Trust & Rapport',
                    description: 'Trust & Rapport is the connection between coach and employee. It’s creating a safe, supportive environment with mutual trust and respect.',
                    qualities: [
                        'Coach is attuned to the employee\'s perceptions and personal being.',
                        'Coach seeks additional information regarding the employee\'s thinking and perceptions about the situation.',
                        'Coach is observant and responsive to the employee\'s feelings.',
                        'Coach trusts the employee and the employee’s processes of thinking and creating.',
                        'Coach asks for permission to coach employee in sensitive areas.',
                    ],
                },
                {
                    name: 'Active Listening',
                    description: 'Active listening is focusing completely on what the employee is saying and not saying. It’s understanding context and supporting the employee in self-expression.',
                qualities: [
                        'Coach hears the employee’s concerns and beliefs about what is possible.',
                       'Coach summarizes, paraphrases, and reflects back what the employee has said to ensure clarity and understanding.',
                        'Coach encourages, explores and reinforces the employee’s expression of feelings, perceptions, concerns, etc…',
                        'Coach is comfortable with using silence as a way of giving the employee space to process.',
                        'Coach is fully present and flexible during the coaching process.',
                        'Coach inquires about the employee’s tone of voice, pace of speech, words, energy shifts and behaviors as appropriate.',
                    ],
                },
                {
                    name: 'Reflective Inquiry',
                    description: 'Reflective Inquiry is asking questions that reveal the information needed for maximum benefit to the employee.',
                qualities: [
                        'Coach’s questions are customized to and seek information about the employee’s agenda.',
                        'Coach asks questions that move the employee forward in their thinking or action around their agenda.',
                        'Coach asks questions that evoke discovery, insight, commitment or action.',
                    ],
                },
                {
                    name: 'Creating Awareness',
                    description: 'Creating awareness is integrating and accurately evaluate multiple sources of information. It’s making interpretations that help the employee gain awareness and achieve agreed upon goals.',
                qualities: [
                        'Coach expresses insights in ways that are useful and meaningful for the employee.',
                        'Coach helps the employee see the different interrelated factors that affect them and their behavior.',
                        'Coach reframes perceptions to help the employee see other perspectives.',
                        'Coach helps the employee to discover new thoughts, perceptions, etc…that help them achieve what is important to them.',
                        'Coach challenges the employee’s assumptions to provoke new ideas and find possibilities for action.',
                        'Coach uses metaphors and analogies to help illustrate a point or paint a verbal picture.',
                    ],
                },
                {
                    name: 'Action Planning',
                    description: 'Action Planning is creating with the employee opportunities for on-going learning and acting toward agreed upon goals. It’s also developing and maintaining an effective coaching plan with the employee.',
                qualities: [
                        'Coach makes suggestions that are related to the employee’s agenda and is unattached to whether the employee acts on the suggestion.',
                        'Coach encourages stretches and challenges but at a comfortable pace.',
                        'Coach invites the employee to explore alternative ideas and solutions, to evaluate options and make decisions.',
                        'Coach helps the employee co-create a plan of action toward their goals, that is specific, measurable and time-bound.',
                        'Coach assists the employee to design actions that move them toward their goal.',
                    ],
                },
                {
                    name: 'Accountability & Support',
                    description: 'This is maintaining attention on what is important to the employee and leave responsibility for acting, with them. It\'s also assisting the employee in the development of measures of success and structures for accountability.',
                    qualities: [
                        'Coach helps the employee define success.',
                        'Coach helps develop a method of managing and measuring progress that is related to the employee’s agenda.',
                        'Coach follows up about the actions the employee committed to previously.',
                        'Coach positively confronts the employee if he/she did not take agreed-upon actions.',
                        'Coach supports and affirms their belief in the employee working toward their goal.',
                        'Coach asks the employee about resources needed to achieve their goal.',
                        'Coach celebrates the employee’s progress toward their goals.',
                        'Coach celebrates the employee\'s progress and perspective shifts during the coaching session.',
                        'Coach requests permission to hold the employee accountable; closed questions are okay when seeking permission.',
                    ],
                },
                {
                    name: 'General',
                    description: 'General guidelines for good coaching.',
                    qualities: [
                        'Coach asks primarily open-ended questions. ',
                        'Coach uses inquiry versus telling methodology.',
                        'Coach allows the employee to do most of the talking.',
                        'Coach asks questions one at a time.',
                        'Coach exhibits curiosity with the intent to learn more.',
                        'Coach is clear and direct when speaking.',
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.slide {
  height: 100% !important;
}

::v-deep .v-carousel__controls {
  position: absolute;
  bottom: 0;
  background: #1976d2 !important;
}
::v-deep .v-window__prev {
  background: #1976d2 !important;
  margin-left: 6px !important;
}

::v-deep .v-window__next {
  background: #1976d2 !important;
  margin-right: 6px !important;
}
</style>
